<template>
  <div>
    <p v-if="sent">
      {{ $translation.t("Invoice has been sent") }}
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon size="18" color="primary" class="th-ml-8" v-on="on">
            mdi-information
          </v-icon>
        </template>
        <span style="max-width: 540px" class="d-block">
          {{
            $translation.t(
              "It might take up to 5 working days. Invoice have been sent to the registered email"
            )
          }}
        </span>
      </v-tooltip>
    </p>
    <v-btn
      color="primary"
      text
      v-else-if="invoice.number"
      @click="showDialog = true"
      :loading="loading"
    >
      {{ $translation.t("Request invoice") }}
    </v-btn>
    <v-dialog v-model="showDialog" max-width="540">
      <th-card body-classes="th-p-40">
        <h6 class="th-mb-24">
          {{ $translation.t("Please fill information for invoice") }}
        </h6>
        <v-form ref="companyInvoiceInfo">
          <v-text-field
            v-model="model.companyName"
            :rules="[rules.required]"
            :label="$translation.t('Company name')"
            outlined
            hide-details="auto"
            class="th-mb-8"
          />
          <v-text-field
            v-model="model.companyCode"
            :label="$translation.t('Company code')"
            outlined
            hide-details="auto"
            class="th-mb-8"
          />
          <v-text-field
            v-model="model.companyVAT"
            :label="$translation.t('Company VAT')"
            outlined
            hide-details="auto"
            class="th-mb-8"
          />
          <v-text-field
            v-model="model.companyAddress"
            :rules="[rules.required]"
            :label="$translation.t('Company address')"
            outlined
            hide-details="auto"
            class="th-mb-8"
          />
        </v-form>
        <div class="text-right">
          <v-btn
            color="primary"
            text
            @click="showDialog = false"
            :disabled="loading"
          >
            {{ $translation.t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="requestInvoice" :loading="loading">
            {{ $translation.t("submit") }}
          </v-btn>
        </div>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import ThCard from "@/components/GeneralComponents/ThCard";
import rules from "@/utils/inputRules";

export default {
  name: "RequestInvoice",
  components: { ThCard },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules,
      loading: false,
      showDialog: false,
      sent: false,
      model: {
        companyName: "",
        companyCode: "",
        companyVAT: "",
        companyAddress: "",
      },
    };
  },
  methods: {
    async requestInvoice() {
      if (!this.$refs?.companyInvoiceInfo?.validate() ?? true) return;

      this.loading = true;
      // this.invoiceStatuses = {
      //     ...this.invoiceStatuses,
      //     [this.invoice.number]: "loading",
      // };

      await this.$axios.post(`/api/v1/payments/subscriptions/request-invoice`, {
        invoice_id: this.invoice.number,
        invoice_date: this.invoice.created,
        invoice_amount: this.invoice.total,
        invoice_currency: this.invoice.currency,
        subscription: this.invoice.subscription,
        ...this.model,
      });

      this.showDialog = false;
      this.sent = true;
      this.loading = false;
      // this.invoiceStatuses = {
      //     ...this.invoiceStatuses,
      //     [this.invoice.number]: "sent",
      // };
    },
  },
};
</script>

<style lang="scss" scoped></style>
