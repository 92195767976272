<template>
  <th-card body-classes="th-p-0">
    <template slot="header">
      <h6 v-html="$translation.t('Invoices')"></h6>
    </template>
    <template slot="body">
      <page-loader class="th-m-24" v-if="loading" />
      <template v-else>
        <v-data-table
          v-if="invoices.length > 0"
          :headers="headers"
          :items="invoices"
          sort-by="created"
          sort-desc
          :items-per-page="10"
          class="elevation-0"
        >
          <template v-slot:item.number="{ item }">
            <p v-if="item.number" class="body2 text-capitalize">
              {{ item.number }}
            </p>
            <p v-else class="body2 text-capitalize">
              {{ $translation.t(item.billing_reason) }}
            </p>
          </template>
          <template v-slot:item.created="{ item }">
            {{ convertDate(item.created) }}
          </template>
          <template v-slot:item.subscription="{ item }">
            <p
              class="body1"
              v-html="$translation.t(subscriptionName(item))"
            ></p>
            <small class="text-muted">{{ item.subscription }}</small>
          </template>
          <template v-slot:item.total="{ item }">
            {{ $price.format(item.total / 100, item.currency) }}
          </template>
          <template v-slot:item.status="{ item }">
            <th-badge solid sm :color="colorByInvoiceStatus(item.status)">
              {{ $translation.t(item.status) }}
            </th-badge>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="primary"
              text
              v-if="item.number && item.url"
              :href="item.url"
              target="_blank"
              id="download_invoice_button"
            >
              {{ $translation.t("Download invoice") }}
            </v-btn>
            <request-invoice v-else :invoice="item" />
          </template>
        </v-data-table>
        <div class="th-px-24 th-pb-24" v-else>
          <p class="body2" v-html="$translation.t('There are no invoices')"></p>
        </div>
      </template>
    </template>
  </th-card>
</template>

<script>
import PageLoader from "@/components/PageLoader";
import ThBadge from "@/components/GeneralComponents/ThBadge";
import dayjs from "dayjs";
import ThCard from "@/components/GeneralComponents/ThCard";
import { mapState } from "vuex";
import RequestInvoice from "@/components/Billing/Invoices/RequestInvoice";
export default {
  name: "Invoices",
  components: { RequestInvoice, ThCard, ThBadge, PageLoader },
  data: () => ({
    loading: true,
    invoiceStatuses: {},
  }),
  async created() {
    this.invoices = await this.$axios.get(
      `/api/v1/payments/subscriptions/invoices`
    );
    this.loading = false;
  },
  computed: {
    headers() {
      return [
        {
          text: this.$translation.t("Invoice"),
          value: "number",
        },
        {
          text: this.$translation.t("Subscription"),
          value: "subscription",
        },
        { text: this.$translation.t("Date"), value: "created" },
        { text: this.$translation.t("Amount"), value: "total" },
        { text: this.$translation.t("Status"), value: "status" },
        { text: "", value: "actions" },
      ];
    },
    ...mapState({
      subscriptions: (state) => state.client.subscriptions,
    }),
  },
  methods: {
    subscriptionName(invoice) {
      return this.subscriptions.find(
        ({ stripe_id }) => stripe_id === invoice.subscription
      )?.name;
    },
    convertDate(unixTime) {
      if (!unixTime) return null;
      return dayjs.unix(unixTime).format("YYYY MM DD");
    },
    colorByInvoiceStatus(status) {
      switch (status?.toLowerCase()) {
        case "paid":
        case "completed":
          return "success";
        case "draft":
          return "light";
        default:
          return "light";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
