var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th-card',{attrs:{"body-classes":"th-p-0"}},[_c('template',{slot:"header"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$translation.t('Invoices'))}})]),_c('template',{slot:"body"},[(_vm.loading)?_c('page-loader',{staticClass:"th-m-24"}):[(_vm.invoices.length > 0)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.invoices,"sort-by":"created","sort-desc":"","items-per-page":10},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [(item.number)?_c('p',{staticClass:"body2 text-capitalize"},[_vm._v(" "+_vm._s(item.number)+" ")]):_c('p',{staticClass:"body2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.$translation.t(item.billing_reason))+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.created))+" ")]}},{key:"item.subscription",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body1",domProps:{"innerHTML":_vm._s(_vm.$translation.t(_vm.subscriptionName(item)))}}),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.subscription))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$price.format(item.total / 100, item.currency))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('th-badge',{attrs:{"solid":"","sm":"","color":_vm.colorByInvoiceStatus(item.status)}},[_vm._v(" "+_vm._s(_vm.$translation.t(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.number && item.url)?_c('v-btn',{attrs:{"color":"primary","text":"","href":item.url,"target":"_blank","id":"download_invoice_button"}},[_vm._v(" "+_vm._s(_vm.$translation.t("Download invoice"))+" ")]):_c('request-invoice',{attrs:{"invoice":item}})]}}],null,false,666926375)}):_c('div',{staticClass:"th-px-24 th-pb-24"},[_c('p',{staticClass:"body2",domProps:{"innerHTML":_vm._s(_vm.$translation.t('There are no invoices'))}})])]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }