<template>
  <div class="row">
    <!--    <div class="col-12 col-lg-6">-->
    <!--      <main-plan-information class="h-100" />-->
    <!--    </div>-->
    <!--    <div class="col-12 col-lg-6">-->
    <!--      <client-payment-method class="h-100" />-->
    <!--    </div>-->
    <div class="col-12 th-mt-24">
      <invoices class="th-mb-24" />
    </div>
  </div>
</template>

<script>
import Invoices from "../components/Billing/Invoices/Invoices";
// import ClientPaymentMethod from "../components/Client/Billing/ClientPaymentMethod";
// import MainPlanInformation from "../components/Client/Billing/MainPlanInformation";

export default {
  name: "Billing",
  components: {
    // MainPlanInformation,
    // ClientPaymentMethod,
    Invoices,
  },
  async beforeCreate() {
    this.$store.dispatch("clientSubscriptions");
  },
};
</script>

<style lang="scss" scoped></style>
